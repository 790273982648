@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        @apply box-border m-0 p-0;
    }

    html, body {
        font-family: 'Open Sans', sans-serif; /* Default font for body */
    }

    .icon-wrapper {
        position: relative;
        display: inline-block;
    }

    .tooltip-text {
        visibility: hidden;
        width: auto;
        background-color: #555555;
        color: #fff;
        text-align: center;
        border-radius: 3px;
        padding: 3px 8px;
        position: absolute;
        z-index: 1;
        bottom: 102%; /* Places the tooltip above the icon */
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: 0.1s ease-in;
    }

    .tooltip-text::after {
        content: "";
        position: absolute;
        top: 100%; /* Position the triangle below the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555555 transparent transparent transparent;
        transition: 0.3s ease-in;
    }

    .icon-wrapper:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }

    /* Prevents scrolling when the modal is open */
    body.modal-open {
        overflow: hidden !important;
        padding-right: 17px; /* Adjust based on your scrollbar width */
    }
}
